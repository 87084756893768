import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import URLParse from 'url-parse'
// Amplify読み込み
import { Amplify } from 'aws-amplify'
import config from './common/aws-exports'
import '@aws-amplify/ui-vue'

// Vuetify
import { registerPlugins } from '@/plugins'
// QR Code
import VueQrcode from '@chenfengyuan/vue-qrcode'

const url = new URLParse(location.href)
config.oauth.redirectSignIn = config.oauth.redirectSignOut = `${url.protocol}//${url.host}/login`
config.oauth.responseType = 'code'
config.oauth.domain = import.meta.env.VITE_APP_OAUTH_DOMAIN
Amplify.configure(config)

const app = createApp(App)

if (VueQrcode.name) app.component(VueQrcode.name, VueQrcode)

registerPlugins(app)

app.use(router).use(store).mount('#app')
