<template>
  <v-app>
    <GlobalHeader />

    <v-main>
      <router-view class="pt-8" />
    </v-main>

    <Snackbar />
  </v-app>
</template>

<script>
import GlobalHeader from '@/components/common/Header'
import Snackbar from '@/components/common/Snackbar'

export default {
  name: 'App',
  components: {
    GlobalHeader,
    Snackbar
  },
  data: () => ({
    title: null
  }),
  async mounted() {
    const routeInstance = this.$route
    this.title = routeInstance.meta.title
  },
  updated() {
    const routeInstance = this.$route
    this.title = routeInstance.meta.title
  }
}
</script>

<style lang="scss">
.v-main {
  background: #fafafa;
}
</style>
