<template>
  <v-snackbar v-model="showSnackbar" :timeout="snackbarTime">
    {{ snackbarText }}

    <template v-slot:actions>
      <v-btn color="primary" variant="text" @click="hide()">閉じる</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('Snackbar')

export default {
  name: 'Snackbar',
  computed: {
    ...mapState(['showSnackbar', 'snackbarTime', 'snackbarText'])
  },
  methods: {
    ...mapMutations(['setShowSnackbar', 'setSnackbarText']),
    hide() {
      this.setShowSnackbar(false)
      this.setSnackbarText('')
    }
  }
}
</script>

<style lang="scss"></style>
