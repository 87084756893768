<template>
  <v-container>
    <v-layout align-content-space-around class="mb-4">
      <div v-if="tenant" class="d-flex align-center">
        <v-btn size="small" icon="mdi-chevron-left" @click="$router.back()" />
        <div class="text-h5 ml-4">{{ tenant.tenantName }}</div>
      </div>
      <v-spacer />
      <v-btn class="mb-4" color="primary" @click="showCreateDialog = true">プロジェクト登録</v-btn>
    </v-layout>
    <v-table>
      <thead>
        <tr>
          <th>プロジェクト名</th>
          <th>担当者名</th>
          <th>担当者メールアドレス</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="isLoading">
        <tr>
          <td colspan="4">
            <v-row justify="center" class="pa-12">
              <v-progress-circular v-if="isLoading" indeterminate :size="32" />
            </v-row>
          </td>
        </tr>
      </tbody>
      <tbody v-if="items?.length == 0 && !isLoading">
        <tr>
          <td colspan="4" class="pa-12 text-center">プロジェクトがありません。</td>
        </tr>
      </tbody>
      <tbody v-if="!isLoading">
        <tr v-for="(item, index) in sliceItems" :key="index">
          <td style="max-width: 360px; min-width: 160px" class="pt-2 pb-2">
            {{ item.projectName }}
          </td>
          <td style="min-width: 120px">{{ item.userName }}</td>
          <td class="pt-2 pb-2" style="max-width: 300px; min-width: 200px; overflow-wrap: anywhere">
            {{ item.email }}
          </td>
          <td width="66" class="pr-2">
            <v-btn
              color="primary"
              variant="outlined"
              @click="
                ;(showEditDialog = true), (selectedItem = { ...item }), console.log(selectedItem)
              "
            >
              表示
            </v-btn>
          </td>
          <td width="130" class="pl-0">
            <v-btn
              v-if="item.url"
              color="primary"
              @click=";(selectedQrItem = { ...item }), (showQrDialog = true)"
            >
              QRコード表示
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
    <!-- Pagination -->
    <div v-if="calculatedPage > 1" class="text-center mt-8">
      <v-pagination
        v-model="page"
        :length="calculatedPage"
        :total-visible="4"
        @update:modelValue="changePage"
      ></v-pagination>
    </div>
    <!-- Registration Modal -->
    <DialogTemplate
      v-if="tenant"
      :show="showCreateDialog"
      title="プロジェクト登録"
      closeButtonText="キャンセル"
      primaryButtonText="登録"
      :disabled="!isValidCreateProrjectForm"
      @close="showCreateDialog = false"
      @action="createProject"
    >
      <v-form v-model="isValidCreateProrjectForm" class="pa-8 pb-0">
        <v-text-field
          v-model="tenant.tenantName"
          variant="outlined"
          label="テナント名"
          disabled
          readonly
        />
        <v-text-field
          v-model="newProjectName"
          variant="outlined"
          label="プロジェクト名"
          :rules="projectNameRule"
          class="mb-2"
        />
        <v-text-field
          v-model="createItem.email"
          variant="outlined"
          label="担当者メールアドレス"
          :rules="mailAddressRules"
        />
      </v-form>
    </DialogTemplate>
    <!-- Edit Modal -->
    <DialogTemplate
      v-if="tenant"
      :show="showEditDialog"
      title="プロジェクト編集"
      closeButtonText="キャンセル"
      primaryButtonText="更新"
      :disabled="!isValidUpdateProrjectForm"
      @close="showEditDialog = false"
      @action="updateProject"
    >
      <v-form v-model="isValidUpdateProrjectForm" class="pa-8 pb-0">
        <copiable-text-field
          variant="outlined"
          label="テナント名"
          disabled
          :value="tenant.tenantName"
        />
        <copiable-text-field
          variant="outlined"
          label="プロジェクトID"
          disabled
          :value="selectedItem.projectId"
        />
        <v-text-field
          v-model="selectedItem.projectName"
          variant="outlined"
          label="プロジェクト名"
          :rules="projectNameRule"
          class="mb-4"
        />
        <copiable-text-field
          v-if="selectedItem.userName"
          variant="outlined"
          label="担当者名"
          disabled
          :value="selectedItem.userName"
        />
        <copiable-text-field
          v-if="selectedItem.userId"
          variant="outlined"
          label="担当者ユーザーID"
          disabled
          :value="selectedItem.userId"
        />
        <v-text-field
          v-if="!selectedItem.userId"
          v-model="selectedItem.email"
          variant="outlined"
          label="担当者メールアドレス"
          :rules="mailAddressRules"
          class="mb-4"
        />
        <copiable-text-field
          v-if="selectedItem.userId"
          variant="outlined"
          label="担当者メールアドレス"
          disabled
          :value="selectedItem.email"
        />
      </v-form>
    </DialogTemplate>

    <!-- QR Code Modal -->
    <QRDialog
      :show="showQrDialog"
      :title="selectedQrItem?.projectName"
      :url="selectedQrItem?.url"
      :email="selectedQrItem?.email"
      @close="showQrDialog = false"
    />

    <!-- ErrorDialog -->
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="hasError = false"
    />
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import dayjs from '@/common/dayjs'
import CopiableTextField from '@/components/common/CopiableTextFielde'
import DialogTemplate from '@/components/common/DialogTemplate'
import ErrorDialog from '@/components/common/ErrorDialog'
import QRDialog from '@/components/QRDialog'

const {
  mapState: mapStateTenant,
  mapMutations: mapMutationsTenant,
  mapActions: mapActionsTenant
} = createNamespacedHelpers('Tenant')
const { mapState: mapStateProject, mapActions: mapActionsProject } =
  createNamespacedHelpers('Project')
const { mapState: mapStateUser, mapActions: mapActionsUser } = createNamespacedHelpers('User')
const { mapMutations: mapMutationsSnackbar } = createNamespacedHelpers('Snackbar')

export default {
  name: 'ProjectList',
  components: {
    CopiableTextField,
    DialogTemplate,
    ErrorDialog,
    QRDialog
  },
  data() {
    return {
      isLoading: false,
      showCreateDialog: false,
      showEditDialog: false,
      showQrDialog: false,
      selectedItem: null,
      tenant: null,
      hasError: false,
      errorTitle: '',
      errorMessage: '',
      isValidCreateProrjectForm: false,
      isValidUpdateProrjectForm: false,
      createItem: {
        tenantId: null,
        email: null,
        role: 'tenant'
      },
      newProjectName: '',
      selectedQrItem: null,
      page: 1,
      projectNameRule: [v => !!v || 'プロジェクト名を入力してください'],
      mailAddressRules: [
        v => !!v || 'メールアドレスを入力してください',
        v => /.+@.+\..+/.test(v) || 'メールアドレスが正しい形式ではありません'
      ]
    }
  },
  computed: {
    ...mapStateTenant(['tenantId', 'tenants']),
    ...mapStateProject(['projectId', 'projectName', 'projects', 'newProjectId']),
    ...mapStateUser(['users', 'userTokens']),
    user() {
      return this.$store.state.user
    },
    items() {
      return this.projects
        .map(p => {
          const contributor = this.users?.find(u =>
            u.subscriptions?.some(s => s.projectId === p.projectId)
          )
          if (contributor) {
            return {
              projectId: p.projectId,
              projectName: p.projectName,
              userId: contributor.userId,
              userName: contributor.userName,
              email: contributor.email,
              createdAt: p.createdAt
            }
          }
          const token = this.userTokens.find(t => t.projectId === p.projectId)
          if (token) {
            return {
              projectId: p.projectId,
              projectName: p.projectName,
              userToken: token.userToken,
              email: token.email,
              url: token.url,
              createdAt: p.createdAt
            }
          }
          return p
        })
        .sort((a, b) => {
          return dayjs(a.createdAt).format('YYYY-MM-DD HH:mm:ss') >
            dayjs(b.createdAt).format('YYYY-MM-DD HH:mm:ss')
            ? -1
            : 1
        })
    },
    calculatedPage() {
      return Math.ceil(this.items.length / 10)
    },
    sliceItems() {
      return this.items.slice((this.page - 1) * 10, this.page * 10)
    }
  },
  async created() {
    this.isLoading = true
    // URLからtenandIdを取得
    this.setTenantId(this.$route.params.id)
    // URLからページ情報を取得
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
    // テナント一覧を取得して対象テナントを絞り込む
    await this.getTenants(this.tenantId, this.projectId)
    this.tenant = this.tenants.find(data => data.tenantId === this.tenantId)

    // Projectの取得に時間かかるので並列で実行
    try {
      await Promise.all([this.getProjects(), this.fetchUsers(), this.fetchUsersTokens()])
    } catch (error) {
      this.hasError = true
      this.errorTitle = 'プロジェクトの取得に失敗しました'
      this.errorMessage = 'プロジェクトの取得に失敗しました。再度お試しください。'
    }
    this.isLoading = false
  },
  methods: {
    ...mapMutationsSnackbar(['setShowSnackbar', 'setSnackbarText']),
    ...mapMutationsTenant(['setTenantId']),
    ...mapActionsTenant(['getTenants']),
    ...mapActionsProject(['getProjects', 'postProject', 'putProject']),
    ...mapActionsUser(['getUsers', 'getUserTokens', 'postUsersTokens', 'putUsersTokens']),
    async fetchUsers() {
      this.createItem.tenantId = this.tenantId

      await this.getUsers({
        tenantId: this.tenantId,
        role: 'tenant'
      })
    },
    async fetchUsersTokens() {
      this.getUserTokens({
        tenantId: this.tenantId,
        role: 'tenant'
      })
    },
    async createProject() {
      try {
        await this.postProject({
          projectName: this.newProjectName
        })
        await this.postUsersTokens({
          ...this.createItem,
          projectId: this.newProjectId
        })

        await this.postUsersTokens({
          role: 'user',
          tenantId: this.tenantId,
          projectId: this.newProjectId
        })

        // 登録処理後に再度取得処理を投げる
        this.showCreateDialog = false
        // 初期化
        this.createItem.email = ''
        this.newProjectName = ''
        await Promise.all([this.getProjects(), this.fetchUsers(), this.fetchUsersTokens()])
        this.setShowSnackbar(true)
        this.setSnackbarText('プロジェクトを追加しました。')
      } catch (e) {
        this.hasError = true
        this.errorTitle = '登録に失敗しました'
        this.errorMessage = '入力内容をご確認の上、再度お試しください。'
      }
    },
    async updateProject() {
      try {
        await Promise.all([
          this.putProject({
            projectId: this.selectedItem.projectId,
            projectName: this.selectedItem.projectName
          }),
          this.selectedItem.userToken
            ? this.putUsersTokens({
                userToken: this.selectedItem.userToken,
                tenantId: this.tenantId,
                projectId: this.selectedItem.projectId,
                role: 'tenant',
                email: this.selectedItem.email
              })
            : undefined,
          !this.selectedItem.userToken && !this.selectedItem.userToken && this.selectedItem.email
            ? this.postUsersTokens({
                tenantId: this.tenantId,
                projectId: this.selectedItem.projectId,
                email: this.selectedItem.email,
                role: 'tenant'
              })
            : undefined
        ])
        this.showEditDialog = false
        await Promise.all([this.getProjects(), this.fetchUsers(), this.fetchUsersTokens()])
        this.setShowSnackbar(true)
        this.setSnackbarText('プロジェクトを更新しました。')
      } catch (e) {
        this.hasError = true
        this.errorTitle = '更新に失敗しました'
        this.errorMessage = '入力内容をご確認の上、再度お試しください。'
      }
    },
    download() {
      const canvas = document.getElementsByTagName('canvas')[0]
      const a = document.createElement('a')
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = 'black'
      ctx.font = '10px Roboto'
      ctx.fillText(this.selectedQrItem.email, 50, 10)
      const [url1, url2] = this.selectedQrItem.url.split('?')
      ctx.fillText(url1, 50, 20)
      ctx.fillText(`?${url2}`, 60, 30)
      a.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      a.download = 'QR code'
      a.click()
    },
    changePage(page) {
      this.page = page
      this.$router.push(`?page=${page}`)
    }
  }
}
</script>
