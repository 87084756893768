<template>
  <v-dialog v-model="showQRCode" width="400" @click:outside="closeDialog">
    <v-card v-if="url" color="white" class="mt-12" min-width="300" max-width="940">
      <v-toolbar :title="title" color="indigo" />
      <v-card-text class="pa-8">
        <copiable-text-field variant="outlined" label="サイトURL" disabled :value="url" />
        <copiable-text-field
          variant="outlined"
          label="担当者メールアドレス"
          disabled
          :value="email"
        />
        <vue-qrcode id="qrcode" class="qr-code" :value="url" tag="img" />
        <v-btn block color="black" size="large" variant="flat" @click="download()">
          ダウンロード
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CopiableTextField from '@/components/common/CopiableTextFielde'
export default {
  name: 'QRDialog',
  components: {
    CopiableTextField
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    url: {
      type: String,
      required: false,
      default: ''
    },
    email: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      clicked: false,
      copied: false
    }
  },
  computed: {
    showQRCode: {
      get() {
        return this.show
      },
      set() {
        this.$emit('hide')
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    download() {
      const qrImage = document.querySelector('#qrcode')
      const a = document.createElement('a')
      a.href = qrImage
      a.download = `QR_${this.title}.png`
      a.click()
    }
  }
}
</script>

<style lang="scss">
.qr-code {
  width: 100%;
}
</style>
